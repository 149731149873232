import React from 'react'
import { BrowserRouter as Router, Switch, Route, HashRouter } from 'react-router-dom'
import { Navbar, Sidebar, Footer } from './components'

import { Home, SingleProduct, Cart, Checkout, Error, About, Products, PrivateRoute, AuthWrapper } from "./pages"


function App() {
  return (
  <AuthWrapper>
    <HashRouter>
      <Navbar />
      <Sidebar />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/cart">
          <Cart />
        </Route>
        <Route exact path="/products">
          <Products />
        </Route>
        <Route exact path="/products/:id" children={<SingleProduct />}/>
        <PrivateRoute exact path="/checkout">
          <Checkout />
        </PrivateRoute>
        <Route path="*">
          <Error />
        </Route>
      </Switch>
      <Footer />
    </HashRouter>
  </AuthWrapper>
  )
}

export default App
