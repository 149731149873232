import React from 'react'

const Loading = () => {
  return (
    <div className="section section-center">
      <div className="loading"></div>
      </div>

  )
}

export default Loading
