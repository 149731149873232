import React from 'react'
import styled from 'styled-components'
import { PageHero, StripeCheckout } from '../components'
// extra imports
import { useCartContext } from '../context/cart_context'
import { Link } from 'react-router-dom'
import cart_reducer from '../reducers/cart_reducer'
import { formatPrice } from '../utils/helpers';
import { useUserContext } from '../context/user_context';
import {
	PayPalScriptProvider,
	PayPalButtons,
} from "@paypal/react-paypal-js";

const OrderButtonWrapper = () => {
  const { total_amount } = useCartContext();
	return (<PayPalButtons
		createOrder={(data, actions) => {
			return actions.order.create({
                    purchase_units: [{
                        amount: {
                            value: formatPrice(total_amount).substring(1)
                        }
                    }]
                });
		}}
	/>);
}
const CheckoutPage = () => {
  const { cart, total_amount, shipping_fee, clearCart } = useCartContext();
  const { myUser } = useUserContext();
  console.log(cart_reducer.length);
  return <main>
    <PageHero title="checkout" />
    <Wrapper className="page">
      {cart.length < 1 ? (
        <div className="empty">
          <h2>your cart is empty</h2>
          <Link to="/products" className="btn">
          fill it
          </Link>
          </div>
      ): (
        <div>
        <article>
          <h4>Hello, {myUser && myUser.name}</h4>
          <p>Your total is {formatPrice(total_amount)}</p>
        </article>
        <PayPalScriptProvider
          options={{
            "client-id": "test",
            components: "buttons",
            "data-namespace": "paypalOrder"
          }}
        >
			<OrderButtonWrapper />
		</PayPalScriptProvider></div>
    )}
    </Wrapper>
  </main>
}
const Wrapper = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  .empty{
    text-align:center;
  }

`
export default CheckoutPage
